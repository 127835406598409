import { Machine, assign } from 'xstate'

const initialValues = {
    type: 'UAE',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }
    const { type } = value

    let formStatus = {}
    Object.keys(dataMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'type' ? true : false
        }
    })

    context = [{
        feature: '',
        filters: [
            {
                Id: 'EF000005',
                Value: 'EV000078',
            }
        ],
        additionalFilters: []
    }]

    if (type === 'UAE') {
        context[0].feature = 'EC000018'
        context[0].filters.push(
            {
                Id: 'EF000133',
                Value: 'EV000154',
            }
        )
        context[0].additionalFilters.push('uae')
    } else if (type === 'TAE') {
        context[0].feature = 'EC000018'
        context[0].filters.push(
            {
                Id: 'EF000133',
                Value: 'EV000378',
            }
        )
    } else if (type === 'SPEAKER') {
        context[0].feature = 'EC000018'
        context[0].filters.push(
            {
                Id: 'EF000133',
                Value: 'EV000154',
            }
        )
        context[0].additionalFilters.push('speaker')
    } else if (type === 'ANTENNA') {
        context[0].feature = 'EC000439'
        context[0].filters = []
    } else if (type === 'OTHER') {
        context[0].feature = 'EC000018'
        context[0].filters.push({
            Id: 'EF000133',
            Value: ['EV004045', 'EV010888', 'EV001102', 'EV015645'],
        })
    }

    return {
        descriptions: context,
        formStatus
    }
}

export const dataMachine = Machine(
    {
        id: 'data',
        type: 'parallel',
        context: {},
        states: {
            type: {
                initial: initialValues.type,
                on: {
                    UAE: 'type.UAE',
                    TAE: 'type.TAE',
                    SPEAKER: 'type.SPEAKER',
                    ANTENNA: 'type.ANTENNA',
                    OTHER: 'type.OTHER',
                },
                states: {
                    UAE: { entry: ['calculate'] },
                    TAE: { entry: ['calculate'] },
                    SPEAKER: { entry: ['calculate'] },
                    ANTENNA: { entry: ['calculate'] },
                    OTHER: { entry: ['calculate'] },
                },
            },
        },
    },
    {
        actions: {
            calculate: assign(calculate),
        },
    }
)

export const possibleDataClasses = {
    classes: ['EC001264', 'EC000018', 'EC000439'],
    types: {
        'UAE': dataMachine.initialState?.context?.descriptions,
        'TAE': dataMachine.transition(dataMachine.initialState, { type: 'TAE' , payload: { identifier: 'type'} })?.context?.descriptions,
        'SPEAKER': dataMachine.transition(dataMachine.initialState, { type: 'SPEAKER', payload: { identifier: 'type'} })?.context?.descriptions,
        'ANTENNA': dataMachine.transition(dataMachine.initialState, { type: 'ANTENNA', payload: { identifier: 'type'} })?.context?.descriptions,
    }
}

export default dataMachine
