import { Machine, assign } from 'xstate'

const initialValues = {
    type: 'DOOR_COMMUNICATION',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }
    const { type } = value

    let formStatus = {}
    Object.keys(doorCommunicationMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'type' ? true : false
        }
    })

    context = [{
        feature: 'EC000779',
        filters: [{
            "Id": "EF000003",
            "Value": "-"
        }],
    }]

    return {
        descriptions: context,
        formStatus
    }
}

export const doorCommunicationMachine = Machine(
    {
        id: 'doorcommunication',
        type: 'parallel',
        context: {},
        states: {
            type: {
                initial: initialValues.type,
                on: {
                },
                states: {
                    DOOR_COMMUNICATION: {
                        entry: ['calculate'],
                    },
                },
            },
        },
    },
    {
        actions: {
            calculate: assign(calculate),
        },
    }
)

export const possibleDoorCommunicationClasses = {
    classes: ['EC000779'],
    types: {
        'DOOR_COMMUNICATION': doorCommunicationMachine.transition(doorCommunicationMachine.initialState, { type: 'DOOR_COMMUNICATION', payload: { identifier: 'type'}})?.context?.descriptions,
        
    }
}

export default doorCommunicationMachine
