import * as etimProto from '../../proto/etim_pb';
import omit from 'lodash/omit'
import { createByteLengthEqualSelector } from '../../vendor/qlib';
import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'
import { calculateDeviceForCPlate } from '../../logic/calculateDeviceForCplate';

export const FETCH_DEVICES_BUFFER_RECEIVE = 'plansoft/availableDevices/FETCH_DEVICES_BUFFER_RECEIVE'

export const initialState = {
    buffer: new ArrayBuffer(0),
    additionalDoorCommunicationData: [],
    additionalBlindData: [],
    additionalCplateData: [],
    additionalDataData: [],
    additionalDimmerData: [],
    additionalMotionData: [],
    additionalSocketData: [],
    additionalSwitchData: [],
    additionalRtrData: [],
    specialSocketData: [],
    synonyms: [],
    cplateMapping: [],
    calculatedTemplates: [],
    designChangeSpecialties: [],
}

const availableDevicesSlice = createSlice({
    name: 'availableDevices',
    initialState,
    reducers: {
        setDevices(state, action) {
            state.buffer = action.payload.buffer
        },
        setAdditionalDoorCommunicationData(state, action) {
            state.additionalDoorCommunicationData = action.payload.data
        },
        setAdditionalBlindData(state, action) {
            state.additionalBlindData = action.payload.data
        },
        setAdditionalCplateData(state, action) {
            state.additionalCplateData = action.payload.data
        },
        setAdditionalDataData(state, action) {
            state.additionalDataData = action.payload.data
        },
        setAdditionalDimmerData(state, action) {
            state.additionalDimmerData = action.payload.data
        },
        setAdditionalMotionData(state, action) {
            state.additionalMotionData = action.payload.data
        },
        setAdditionalSocketData(state, action) {
            state.additionalSocketData = action.payload.data
        },
        setAdditionalSwitchData(state, action) {
            state.additionalSwitchData = action.payload.data
        },
        setAdditionalRtrData(state, action) {
            state.additionalRtrData = action.payload.data
        },
        setSpecialSocketData(state, action) {
            state.specialSocketData = action.payload.data
        },
        setSynonyms(state, action) {
            state.synonyms = action.payload.synonyms
        },
        setCPlateMapping(state, action) {
            state.cplateMapping = action.payload.cplateMapping
        },
        setFrameMapping(state, action) {
            state.frameMapping = action.payload.frameMapping
        },
        setCalculatedTemplates(state, action) {
            state.calculatedTemplates = action.payload.calculatedTemplates
        },
        setDesignChangeSpecialties(state, action) {
            state.designChangeSpecialties = action.payload.designChangeSpecialties
        }
        
    },
})

export const {
    setDevices,
    setSynonyms,
    setCPlateMapping,
    setFrameMapping,
    setCalculatedTemplates,
    setAdditionalDoorCommunicationData,
    setAdditionalBlindData,
    setAdditionalCplateData,
    setAdditionalDataData,
    setAdditionalDimmerData,
    setAdditionalMotionData,
    setAdditionalSocketData,
    setAdditionalSwitchData,
    setAdditionalRtrData,
    setSpecialSocketData,
    setDesignChangeSpecialties
} = availableDevicesSlice.actions
export default availableDevicesSlice.reducer

export const getCalculatedTemplates = state => state.availableDevices.calculatedTemplates
export const getFrameMapping = state => state.availableDevices.frameMapping
export const getCplateMapping = state => state.availableDevices.cplateMapping
export const getSynonyms = state => state.availableDevices.synonyms
export const getDesignChangeSpecialties = state => state.availableDevices.designChangeSpecialties

export const getAdditionalDoorCommunicationData = state => state.availableDevices.additionalDoorCommunicationData
export const getAdditionalBlindData = state => state.availableDevices.additionalBlindData
export const getAdditionalCplateData = state => state.availableDevices.additionalCplateData
export const getAdditionalDataData = state => state.availableDevices.additionalDataData
export const getAdditionalDimmerData = state => state.availableDevices.additionalDimmerData
export const getAdditionalMotionData = state => state.availableDevices.additionalMotionData
export const getAdditionalSocketData = state => state.availableDevices.additionalSocketData
export const getAdditionalSwitchData = state => state.availableDevices.additionalSwitchData
export const getAdditionalRtrData = state => state.availableDevices.additionalRtrData
export const getSpecialSocketData = state => state.availableDevices.specialSocketData

export const getAdditionalData = createByteLengthEqualSelector(
    [getAdditionalBlindData, getAdditionalCplateData, getAdditionalDataData, getAdditionalDimmerData,  getAdditionalMotionData, getAdditionalSocketData, getAdditionalSwitchData, getAdditionalRtrData, getAdditionalDoorCommunicationData],
    (additionalBlindData, additionalCplateData, additionalDataData, additionalDimmerData, additionalMotionData, additionalSocketData, additionalSwitchData, additionalRtrData, additionalDoorCommunicationData) => {
        return [
            ...additionalBlindData,
            ...additionalCplateData,
            ...additionalDataData,
            ...additionalDimmerData,
            ...additionalMotionData,
            ...additionalSocketData,
            ...additionalSwitchData,
            ...additionalRtrData,
            ...additionalDoorCommunicationData
        ].reduce((arr, data) => {
            const index = arr.findIndex(el => el.supplier_pid === data.supplier_pid)
            if (index > -1) {
                let element = {
                    ...omit(arr[index], ['additionalArticles', 'exclude']),
                    ...omit(data, ['additionalArticles', 'exclude']),
                }
                const additionalArticles = [
                    ...(arr[index].additionalArticles?.split(', ') || []),
                    ...(data.additionalArticles?.split(', ') || [])
                ]

                if (additionalArticles.length > 0) {
                    element.additionalArticles = additionalArticles
                        .filter((el, index, self) => index === self.indexOf(el))
                        .join(', ')
                }

                element.exclude = {
                    ...arr[index].exclude,
                    ...data.exclude
                }
 
                arr[index] = element
            } else {
                arr.push(data)
            }
            return arr
        }, [])
    }
)

export const getAvailableDeviceBuffer = state => state.availableDevices.buffer

export const getDeviceList = createByteLengthEqualSelector(
    [getAvailableDeviceBuffer],
    (buffer) => {
        if (buffer && buffer.byteLength > 0) {
            return etimProto.DevicesProto.deserializeBinary(buffer).getDevicesList()
        }
        return []
    }
)

const getAdditionalDataResolved = (additionalData, deviceList) => {
    if (Array.isArray(additionalData)) {
        return additionalData.map(el => {
            let additionalArticles
            if (el.additionalArticles) {
                additionalArticles = el.additionalArticles.replaceAll(' ', '').split(',').map(id => {
                    const device = deviceList.find(dev => {
                        return dev.getSupplierpid() === id
                    })
                    if (device) {
                        return {
                            supplierPid: id,
                            manufacturerTypeDescr: device.getManufacturertypedescr(),
                            features: device.getFeatures()
                        }  
                    }
                    return {
                        supplierPid: id
                    }
                })
            }
            return {
                ...el,
                additionalArticles: additionalArticles
            }
        })
    }
    return additionalData
}

export const getAdditionalSwitchDataResolved = createByteLengthEqualSelector(
    [getAdditionalSwitchData, getDeviceList],
    (additionalSwitchData, deviceList) => {
        return getAdditionalDataResolved(additionalSwitchData, deviceList)
    }
)

export const getAdditionalDoorCommunicationDataResolved = createByteLengthEqualSelector(
    [getAdditionalDoorCommunicationData, getDeviceList],
    (additionalDoorCommunicationData, deviceList) => {
        return getAdditionalDataResolved(additionalDoorCommunicationData, deviceList)
    }
)

export const getAdditionalBlindDataResolved = createByteLengthEqualSelector(
    [getAdditionalBlindData, getDeviceList],
    (additionalBlindData, deviceList) => {
        return getAdditionalDataResolved(additionalBlindData, deviceList)
    }
)

export const getAdditionalDimmerDataResolved = createByteLengthEqualSelector(
    [getAdditionalDimmerData, getDeviceList],
    (additionalDimmerData, deviceList) => {
        return getAdditionalDataResolved(additionalDimmerData, deviceList)
    }
)

export const getAdditionalMotionDataResolved = createByteLengthEqualSelector(
    [getAdditionalMotionData, getDeviceList],
    (additionalMotionData, deviceList) => {
        return getAdditionalDataResolved(additionalMotionData, deviceList)
    }
)

const getSerie = (state) => state?.projects?.data?.serie 

export const getDevicesWithoutFrame = createByteLengthEqualSelector(
    [getDeviceList],
    (deviceList) => {
        if (deviceList && Array.isArray(deviceList)) {
            return deviceList
                .filter(el => {
                    let features = el?.getFeatures() ? JSON.parse(el.getFeatures()) : null
                    return features && Array.isArray(features) && (features[0].Id === 'EC000450' || features[0].Id === 'EC000779')
                })
                .map(el => el.getSupplierpid()) 
        }
        return []
    }
)

export const getOriginDevice = (state, availableDeviceId) => {
    return createDraftSafeSelector(
        [getDeviceList],
        (deviceList) => {
            if (availableDeviceId && deviceList) {
                return deviceList.find(el => el.getSupplierpid() === availableDeviceId)
            }
            return null
        }
    )(state)
}

export const getCplatesForColors = (state, data) => {
    return createDraftSafeSelector(
        [getDeviceList, getCplateMapping, getAdditionalData, getSerie],
        (deviceList, cPlateMapping, additionalData, serie) => {
            return data.map(obj => {
                const originDevice = deviceList.find(el => {
                    return el.getSupplierpid() === obj.availableDeviceId
                })
            
                const articleColor = cPlateMapping.find(
                    el => el.serie === parseInt(obj.seriesId) && el.colorId === parseInt(obj.cPlateColorId)
                )?.articleColor

                if (originDevice && articleColor) {
                    const cPlates = calculateDeviceForCPlate(
                        obj.availableDeviceId,
                        deviceList,
                        JSON.parse(originDevice.getFeatures()),
                        articleColor,
                        additionalData,
                        [],
                        'full',
                        serie
                    )

                    const articleNumber = originDevice.getManufacturertypedescr ? originDevice.getManufacturertypedescr() : null
                    const splits = articleNumber ? articleNumber.split('-') : []
                    const id = (splits.length > 1 ? splits.slice(0, -1) : splits).join('-')
                    const defaults = cPlates.filter(el => el.IsDefault)

                    if (defaults.length > 0) {
                        return cPlates.find(el => el.manufacturerTypeDescr.includes(id)) || defaults[0]
                    }

                    return cPlates.find(el => el.manufacturerTypeDescr.includes(id)) || cPlates[0]
                }
    
                return null
            })
        }
    )(state)
}

export const getCplate = (state, serieId, cPlateColorId, availableDeviceId, limit = false, additionalArticles, orientation) => {
    return createDraftSafeSelector(
        [getDeviceList, getCplateMapping, getAdditionalData, getSerie],
        (deviceList, cPlateMapping, additionalData, serie) => {
            const originDevice = deviceList.find(el => el.getSupplierpid() === availableDeviceId)
            const articleColor = cPlateMapping.find(
                el => el.serie === parseInt(serieId) && el.colorId === parseInt(cPlateColorId)
            )?.articleColor
            if (originDevice && articleColor) {
                const cPlates = calculateDeviceForCPlate(
                    availableDeviceId,
                    deviceList,
                    JSON.parse(originDevice.getFeatures()),
                    articleColor,
                    additionalData,
                    additionalArticles,
                    orientation,
                    serie
                )
                if (limit) {
                    return cPlates.find(el => el.IsDefault) || cPlates[0]
                } else {
                    return cPlates
                }
            }

            return null
        }
    )(state)
}

export const defaultCurrency = 'EUR'

export const getDefaultCplate = serieId => {
    return serieId === 64 
        ? '2CKA001710A4248'
        : '2CKA001012A1069'
}