import Axios from 'axios'
import Cookies from 'js-cookie'
import { normalize, schema } from 'normalizr'
import { createSelector } from 'reselect'
import { toast } from 'react-toastify'
import { queryClient } from '../../vendor/queryClient'
import etimDB from '../../indexedDB/etimDB'

export const FETCH_SERIES = 'raumplaner/api/SERIES'
export const FETCH_COLORS = 'raumplaner/api/COLORS'
export const FETCH_FRAMEMAPPING = 'raumplaner/api/FRAMEMAPPING'
export const FETCH_CPLATEMAPPING = 'raumplaner/api/CPLATEMAPPING'
export const FETCH_CENTRALPLATE = 'raumplaner/api/CENTRALPLATE'
export const FETCH_CALCULATED_DATA = 'raumplaner/api/CALCULATED_DATA'
export const FETCH_FAVORITES = 'raumplaner/api/FAVORITES'
export const FETCH_DEVICES_PROTO = 'raumplaner/api/DEVICES_PROTO'
export const FETCH_USER_DATA = 'raumplaner/api/USER_DATA'
export const FETCH_IMAGE = 'raumplaner/api/FETCH_IMAGE'
export const FETCH_FRAME = 'raumplaner/api/FETCH_FRAME'
export const FETCH_FRAMEPOSITIONS = 'raumplaner/api/FETCH_FRAMEPOSITIONS'
export const FETCH_SYNONYMS = 'raumplaner/api/FETCH_SYNONYMS'
export const FETCH_PROJECTS = 'raumplaner/api/FETCH_PROJECTS'
export const FETCH_PROJECT = 'raumplaner/api/FETCH_PROJECT'
export const FETCH_GLOBAL_SETTINGS = 'raumplaner/api/FETCH_GLOBAL_SETTINGS'
export const FETCH_DESIGN_CHANGE_SPECIALTIES = 'raumplaner/api/FETCH_DESIGN_CHANGE_SPECIALTIES'
export const FETCH_DEVICE_CLASSES = 'raumplaner/api/FETCH_DEVICE_CLASSES'
export const FETCH_DEVICE_FEATURES = 'raumplaner/api/FETCH_DEVICE_FEATURES'
export const FETCH_DEVICE_VALUES = 'raumplaner/api/FETCH_DEVICE_VALUES'

export const getQueryCache = queryKey => {
    return queryClient.getQueryData(queryKey)
}

export const getIsQueryFetching = queryKey => {
    return queryClient.getQueryState(queryKey)
}

export const getIsLoadDevices = createSelector([], () => {
    return (
        queryClient.isFetching(FETCH_DEVICES_PROTO)
        || queryClient.isFetching(FETCH_CALCULATED_DATA)
        || queryClient.isFetching(FETCH_CPLATEMAPPING) 
        || queryClient.isFetching(FETCH_FRAMEMAPPING)
        //|| queryClient.isFetching(FETCH_FAVORITES)
    )
})

const seriesEntity = new schema.Entity('series', {}, { idAttribute: 'id' })
const seriesSchema = { series: [seriesEntity] }

export const fetchSeries = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_TERMINAL_API_URL + '/api/designranges',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(series) {
            return normalize({ series: series }, seriesSchema)
        })
}

const colorsEntity = new schema.Entity('colors', {}, { idAttribute: 'id' })
const colorsSchema = { colors: [colorsEntity] }

export const fetchColors = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_TERMINAL_API_URL + '/api/colors',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(colors) {
            return normalize({ colors: colors }, colorsSchema)
        })
}

export const fetchFrameMapping = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/frameMapping',
    }).then(response => {
        if (response.status >= 400) {
            //eslint-disable-next-line no-throw-literal
            throw `${response.status} ${response.statusText}`
        }
        return response.data
    })
}

export const fetchCplateMapping = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/cplateMapping',
    }).then(response => {
        if (response.status >= 400) {
            //eslint-disable-next-line no-throw-literal
            throw `${response.status} ${response.statusText}`
        }
        return response.data
    })
}

export const fetchDesginChangeSpecialties = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/designChangeSpecialties',
    }).then(response => {
        if (response.status >= 400) {
            //eslint-disable-next-line no-throw-literal
            throw `${response.status} ${response.statusText}`
        }
        return response.data
    })
}

export function getFramePositions(rangeID = 28, frameTypeID = 5, width = 200, orientation = 'V') {
    if (frameTypeID === 0) {
        return Promise.resolve({
            width: width,
            height: width,
            position: [{x: 0, y: 0}]
        })
    }
    
    return Axios.post(`${process.env.REACT_APP_TERMINAL_API_URL}/api/framePositions`, {
        rangeID,
        frameTypeID,
        width,
        orientation,
    })
        .then(resp => {
            if (resp.status === 200) {
                return resp.data
            } else {
                return []
            }
        })
        .catch(() => {
            return Promise.resolve([])
        })
}

export const fetchCalculatedData = () => {
    return Axios.get(`${process.env.REACT_APP_API_URL}/api/templates/calculate`)
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(data) {
            return data
        })
}

export const fetchFavorites = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/favorites',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(data) {
            if (data) {
                let favorites = data.map(el => {
                    return {
                        ...el,
                        data: JSON.parse(el.data),
                    }
                })

                return favorites
            }
            return []
        })
}

export const fetchDevicesProto = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/proto/devices',
        responseType: 'arraybuffer',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(buffer => {
            etimDB.etim.update('fetchDate', { value: new Date() })
            etimDB.etim.update('buffer', { value: buffer })
            return buffer
        })
}

export const fetchSynoyms = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/synonyms',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(synonyms => {
            etimDB.etim.update('categoryLength', { value: synonyms.length })
            return synonyms
        })
}

export const getFrameColors = createSelector(
    [args => args.series, args => args.frameMapping, args => args.colors],
    (series, frameMapping, colors) => {
        let frameColors = {}
        if (series && frameMapping && colors) {
            series.forEach(serie => {
                frameColors[serie.id] = frameMapping
                    .filter(el => {
                        return el.serie === parseInt(serie.id)
                    })
                    .map(el => {
                        if (el.type && el.type !== '') {
                            return {
                                ...colors.entities.colors[el.colorId],
                                type: el.type
                            }
                        }
                        return colors.entities.colors[el.colorId]
                    })
            })
        }
        return frameColors
    }
)

export const getCplateColors = createSelector(
    [args => args.series, args => args.cPlateMapping, args => args.colors],
    (series, cPlateMapping, colors) => {
        let frameColors = {}
        if (series && cPlateMapping && colors) {
            series.forEach(serie => {
                frameColors[serie.id] = cPlateMapping
                    .filter(el => {
                        return el.serie === parseInt(serie.id)
                    })
                    .map(el => {
                        return colors.entities.colors[el.colorId]
                    })
            })
        }
        return frameColors
    }
)

export const getFrameColorsOfSerie = createSelector(
    [args => args.serieId, args => args.frameMapping, args => args.colors],
    (serieId, frameMapping, colors) => {
        let frameColors = []
        if (serieId && frameMapping && colors) {
            frameColors = frameMapping
                .filter(el => {
                    return el.serie === parseInt(serieId)
                })
                .map(el => {
                    return colors.entities.colors[el.colorId]
                })
        }
        return frameColors
    }
)

export const getCplateColorsOfSerie = createSelector(
    [args => args.serieId, args => args.cPlateMapping, args => args.colors],
    (serieId, cPlateMapping, colors) => {
        let frameColors = []
        if (serieId && cPlateMapping && colors) {
            frameColors = cPlateMapping
                .filter(el => {
                    return el.serie === parseInt(serieId)
                })
                .map(el => {
                    return colors.entities.colors[el.colorId]
                })
        }
        return frameColors
    }
)

export const fetchImage = src => {
    return Axios({
        method: 'GET',
        url: src,
        responseType: 'arraybuffer',
    }).then(response => {
        if (response.status >= 400) {
            //eslint-disable-next-line no-throw-literal
            throw `${response.status} ${response.statusText}`
        }
        return 'data:img/png;base64,' + new Buffer(response.data, 'binary').toString('base64')
    })
}

export const fetchUserData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_MYBUILDINGSAPI + 'user', //eslint-disable-line no-undef
    }).then(response => {
        if (response.status >= 400) {
            //eslint-disable-next-line no-throw-literal
            throw `${response.status} ${response.statusText}`
        }
        return response.data
    })
}

export const ssoAuth = code => {
    let xhr = new XMLHttpRequest()
    return new Promise((resolve, reject) => {
        var data = new FormData()
        data.append('client_id', process.env.REACT_APP_SSOCLIENTID)
        data.append('client_secret', process.env.REACT_APP_SSOCLIENTSECRET)
        data.append('grant_type', 'authorization_code')
        data.append('code', code)
        xhr.open('POST', process.env.REACT_APP_SSOURL + 'token', true)
        xhr.addEventListener('readystatechange', function() {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const response = JSON.parse(xhr.responseText)
                    Cookies.set('access_token', response['access_token'], { expires: 30, secure: true, HttpOnly: true })
                    Cookies.set('refresh_token', response['refresh_token'], { expires: 90, secure: true, HttpOnly: true })
                    resolve(true)
                } else {
                    Cookies.remove('access_token')
                    Cookies.remove('refresh_token')
                    resolve(false)
                }
            }
        })
        xhr.send(data)
    })
}

export const ssoRefresh = () => {
    let xhr = new XMLHttpRequest()
    return new Promise((resolve, reject) => {
        var data = new FormData()
        data.append('client_id', process.env.REACT_APP_SSOCLIENTID)
        data.append('client_secret', process.env.REACT_APP_SSOCLIENTSECRET)
        data.append('grant_type', 'refresh_token')
        data.append('refresh_token', Cookies.get('refresh_token'))
        xhr.open('POST', process.env.REACT_APP_SSOURL + 'token', true)
        xhr.addEventListener('readystatechange', function() {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const response = JSON.parse(xhr.responseText)
                    Cookies.set('access_token', response['access_token'], { expires: 30, secure: true, HttpOnly: true })
                    Cookies.set('refresh_token', response['refresh_token'], { expires: 90, secure: true, HttpOnly: true })
                    resolve(true)
                } else {
                    Cookies.remove('access_token')
                    Cookies.remove('refresh_token')
                    resolve(false)
                }
            }
        })
        xhr.send(data)
    })
}

Axios.interceptors.request.use(
    function(config) {
        const token = Cookies.get('access_token')

        if (token !== undefined) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    function(err) {
        return Promise.reject(err)
    }
)

Axios.interceptors.response.use(
    function(response) {
        return response
    },
    function(error) {
        try {
            const {
                config,
                response: { status },
            } = error
            const originalRequest = config

            if (status === 401) {
                ssoRefresh().then(resp => {
                    if (resp) {
                        return originalRequest
                    }
                })
            }
            return Promise.reject(error)
        } catch (e) {
            console.log('Auth. error')
            return Promise.reject(error)
        }
    }
)

export const getIsDevicesFetching = state => getIsQueryFetching(FETCH_DEVICES_PROTO)
/*export const getAvailableDeviceBuffer = () => getQueryCache(FETCH_DEVICES_PROTO) || new ArrayBuffer(0)

export const getDeviceList = createByteLengthEqualSelector(
    [getAvailableDeviceBuffer],
    (buffer) => {
        if (buffer && buffer.byteLength > 0) {
            return etimProto.DevicesProto.deserializeBinary(buffer).getDevicesList()
        }
        return []
    }
)*/

export const getUser = () => getQueryCache(FETCH_USER_DATA) || {}
export const getUserName = () => getUser()?.username
export const isAdmin = () => {
    const user = getUser()
    if (user?.roles && Array.isArray(user.roles)) {
        return user.roles.find(el => el === 'ROLE_SSO_PLANSOFT_ADMIN')
    }
    return false
}

export const getIsLoggedIn = () => {
    return getUser()?.username !== '' && Cookies.get('access_token') !== undefined
}
export const getIsUserFetching = () => getIsQueryFetching[FETCH_USER_DATA]

export const fetchProjects = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/projects',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(projects) {
            let mappedProjects = projects || []
            mappedProjects = mappedProjects.map((project, index) => {
                let data = JSON.parse(project.data)
                return {
                    ...project,
                    data,
                }
            })
            return mappedProjects
        })
        .catch(function(error) {
            //throwError(error)
            return Promise.reject(error)
        })
}

export const fetchProject = id => {
    return Axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/projects/${id}`,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(project) {
            return project
        })
        .catch(function(error) {
            //throwError(error)
            return Promise.reject(error)
        })
}

export const saveProject = (project, entities, intl) => {
    if (project) {
        return Axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + `/api/projects`,
            data: {
                ...project.data,
                entities,
            },
        })
            .then(response => {
                if (response.status >= 400) {
                    //eslint-disable-next-line no-throw-literal
                    throw `${response.status} ${response.statusText}`
                }
                return response.data
            })
            .then(function(data) {
                toast.success(
                    intl.formatMessage({
                        id: `project.save.success`,
                        defaultMessage: 'Projekt erfolgreich gespeichert.',
                    })
                )
                let response = Promise.resolve(null)
                if (data?.id) {
                    response = queryClient.fetchQuery(FETCH_PROJECT, () => fetchProject(data.id))
                }
                return response
            })
            .catch(function(error) {
                toast.error(
                    intl.formatMessage({
                        id: `project.save.error`,
                        defaultMessage: 'Projekt speichern fehlgeschlagen.',
                    })
                )
                //throwError(error)
            })
    } else {
        return Promise.resolve(null)
    }
}

export const fetchUpdateProject = (project, entities) => {
    if (project) {
        return Axios({
            method: 'PUT',
            url: process.env.REACT_APP_API_URL + `/api/projects/${project.ID}`,
            data: {
                ...project.data,
                entities,
            },
        })
            .then(response => {
                if (response.status >= 400) {
                    //eslint-disable-next-line no-throw-literal
                    throw `${response.status} ${response.statusText}`
                }
                return response.data
            })
            .then(function() {
                return true
            })
            .catch(function(error) {
                //throwError(error)
            })
    } else {
        return Promise.resolve(null)
    }
}

export const removeProject = id => {
    Axios({
        method: 'DELETE',
        url: process.env.REACT_APP_API_URL + `/api/projects/${id}`,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .catch(function(error) {
            //throwError(error)
        })
}

export const fetchAdditionalDoorCommunicationData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalDoorCommunicationData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalBlindData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalBlindData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalCplateData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalCplateData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalDataData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalDataData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalDimmerData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalDimmerData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalMotionData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalMotionData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalSocketData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalSocketData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalSwitchData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalSwitchData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchAdditionalRtrData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/additionalRtrData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data.map(el => {
                if (el?.exclude) {
                    el.exclude = JSON.parse(el.exclude)
                }
                return el
            })
        })
}

export const fetchSpecialSocketData = () => {
    return Axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/api/devices/specialSocketData',
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(data => {
            return data
        })
}

export const fetchGlobalSettings = () => {
    return Axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/globalSetting`,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(data) {
            return data
        })
        .catch(function(error) {
            //throwError(error)
            return Promise.reject(error)
        })
}

export const saveGlobalSettings = data => {
    return Axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/globalSetting`,
        data,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(project) {
            return project
        })
        .catch(function(error) {
            //throwError(error)
            return Promise.reject(error)
        })
}

export const fetchETIMChecksum = () => {
    return new Promise((resolve, reject) => {
        return Axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/api/devices/checksum/catalog',
        })
            .then(response => {
                if (response.status >= 400) {
                    //eslint-disable-next-line no-throw-literal
                    throw `${response.status} ${response.statusText}`
                }
                return response.data
            })
            .then(function(checksum) {
                etimDB.etim.update('checksum', { value: checksum })
                return resolve(checksum)
            })
            .catch(function(error) {
                return reject(error)
            })
    })
}


export const fetchDeviceClasses = () => {
    return Axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/devices/classes`,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(data) {
            return data
        })
        .catch(function(error) {
            //throwError(error)
            return Promise.reject(error)
        })
}

export const fetchDeviceFeatures = () => {
    return Axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/devices/features`,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(data) {
            return data
        })
        .catch(function(error) {
            //throwError(error)
            return Promise.reject(error)
        })
}

export const fetchDeviceValues = () => {
    return Axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/devices/values`,
    })
        .then(response => {
            if (response.status >= 400) {
                //eslint-disable-next-line no-throw-literal
                throw `${response.status} ${response.statusText}`
            }
            return response.data
        })
        .then(function(data) {
            return data
        })
        .catch(function(error) {
            //throwError(error)
            return Promise.reject(error)
        })
}

export const exportPlansoft = (data) => {
    return Axios({
        method: "POST",
        url: process.env.REACT_APP_PLANSOFT_API_URL + "/api/projects",
        data,
    })
}
