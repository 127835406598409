import v4 from 'node-uuid'
import { batchActions } from 'redux-batched-actions'

import { getCplateMapping, getDeviceList } from '../../availableDevices/duck'
import { getEditableProject } from '../../projects/duck'
import { addDevice, deleteDevice } from '../device/duck'
import { getAllOperationPoints, getIsSocket } from '../duck'
import { dereferenceDeviceWithOperationPoint, referenceDeviceWithOperationPoint } from '../operationPoint/duck'
import { calculatePossibleDevice } from '../../../logic/calculatePossibleDevice'

export function calculateTouchProtection(dispatch, getState, touchProtection) {
    let actions = []

    const state = getState()
    const project = getEditableProject(state)
    const deviceList = getDeviceList(state)

    let devices = deviceList.map(dev => {
        return {
            supplierPid: dev.getSupplierpid(),
            manufacturerTypeDescr: dev.getManufacturertypedescr(),
            features: dev.getFeatures(),
        }
    })

    const cPlateMapping = getCplateMapping(state)
    const articleColor = cPlateMapping.find(
        el => el.serie === project.data.serie && el.colorId === project.data.cplateColor
    )?.articleColor

    let description = {
        feature: 'EC000125',
        manufacturerTypeDescr: '-' + articleColor,
        filters: [],
    }

    const operationPoints = getAllOperationPoints(state)
    operationPoints.forEach(operationPoint => {
        if (operationPoint.devices) {
            operationPoint.devices.forEach(device => {
                if (device) {
                    if (getIsSocket(state, device.availableDeviceId)) {
                        const curDev = devices.find(el => el.supplierPid === device.availableDeviceId)
                        const features = JSON.parse(curDev.features)[0].Feature
                        let filters = []
                        features.forEach(feature => {
                            if (feature.Fname === 'EF000389') {
                                filters.push({
                                    Id: feature.Fname,
                                    Value: `${touchProtection}`,
                                })
                            } else {
                                filters.push({
                                    Id: feature.Fname,
                                    Value: feature.Fvalue,
                                })
                            }
                        })
                        const newDevices = calculatePossibleDevice(deviceList, [{ ...description, filters }], undefined, project?.data?.serie)
                        const newDevice = newDevices[0]
                        if (newDevice && curDev.supplierPid !== newDevice.supplierPid) {
                            actions.push(deleteDevice(device.id))
                            actions.push(dereferenceDeviceWithOperationPoint(operationPoint.id, device.id))
                            const deviceId = v4()
                            actions.push(addDevice(newDevice.supplierPid, operationPoint.id, deviceId, 'device'))
                            actions.push(referenceDeviceWithOperationPoint(operationPoint.id, deviceId))
                        }
                    }
                }
            })
        }
    })

    dispatch(batchActions(actions))
}
