import { getEditableProject } from '../../components/projects/duck'
import { fetchUpdateProject, getIsLoggedIn } from '../../components/api'
import { getAllEntities } from '../../components/entities/duck'

export const updateProject = store => next => action => {
    let result = next(action)
    let actions = getActions(action)
    const isLoggedIn = getIsLoggedIn(store.getState())
    const project = getEditableProject(store.getState())
    const entities = getAllEntities(store.getState())


    if (isLoggedIn && project?.ID !== 'local') {
        if (
            actions.find(
                action => action.type.includes('raumplaner/entities') || action.type.includes('raumplaner/projects')
            )
        ) {
            fetchUpdateProject(project, entities)
        }
    }
    return result
}

export default updateProject

const getActions = action => {
    let actions = []
    if (action.type === 'BATCHING_REDUCER.BATCH') {
        action.payload.map(action => {
            actions = actions.concat(getActions(action))
            return null
        })
    } else {
        actions.push(action)
    }
    return actions
}
