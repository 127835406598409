import { Machine, assign } from 'xstate'

const initialValues = {
    type: 'TYPE_NONE',
}

export const calculate = (context, event, actionMeta) => {
    let value = JSON.parse(JSON.stringify(actionMeta.state?.value || initialValues))
    if (event?.payload?.identifier) {
        value[event.payload.identifier] = event.type
    }
    
    let formStatus = {}
    Object.keys(rtrMachine.states).forEach(key => {
        formStatus[key] = {
            open: key === 'type' ? true : false
        }
    })

    context = [{
        feature: ['EC010927', 'EC011309'],
        filters: [
        ],
        additionalFilters: []
    }]
    
    return {
        descriptions: context,
        formStatus
    }
}

export const rtrMachine = Machine(
    {
        id: 'rtr',
        type: 'parallel',
        context: {},
        states: {
            type: {
                initial: initialValues.type,
                on: {
                    TYPE_NONE: 'type.TYPE_NONE',
                },
                states: {
                    TYPE_NONE: {
                        entry: ['calculate'],
                    },
                },
            },
        },
    },
    {
        actions: {
            calculate: assign(calculate),
        },
    }
)

export const possibleRtrClasses = {
    classes: ['EC010927', 'EC011309'],
    types: {
        'TYPE_NONE': rtrMachine.initialState?.context?.descriptions,
    }
}

export default rtrMachine
